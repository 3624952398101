import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section, Link, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"poslugi-ta-cini"} />
		<Helmet>
			<title>
				Outdoor Stride Hub
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:title"} content={"Outdoor Stride Hub"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				ExpertPathway
			</Override>
		</Components.Header>
		<Section padding="64px 0" sm-padding="40px 0" font="--base" color="--dark">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				max-width="520px"
				margin="0 auto 5px auto"
				text-align="center"
			>
				Послуги
			</Text>
			<Text
				as="p"
				color="--grey"
				max-width="520px"
				margin="0 auto"
				text-align="center"
				font="--lead"
			>
				<Strong font="--headline3" color="--darkL1">
					Оренда спорядження для зимових видів спорту
				</Strong>
			</Text>
			<Box
				margin="40px -16px -16px -16px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="24px"
				md-grid-gap="16px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="32px"
				width="100%"
			>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/6745953828b5e30023ae7020/images/snowboard%20ski.jpg?v=2024-11-26T12:48:05.137Z) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Оренда лиж і сноубордів
					</Text>
					<Text color="--darkL1" margin="0" font="--lead">
						Вибір лижного чи сноубордного спорядження може бути складним, але з нами це просто. У нашому каталозі представлені:
					</Text>
					<Text as="p" font="--lead" color="--darkL2">
						Гірські лижі для будь-якого рівня досвіду.
						<br />
						Сноуборди для фрістайлу, слалому та спусків.
						<br />
						Комплекти з черевиками, палицями та шоломами.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://uploads.quarkly.io/6745953828b5e30023ae7020/images/325232-1.jpg?v=2024-11-26T13:08:17.497Z) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Оренда ковзанів
					</Text>
					<Text color="--darkL1" margin="0" font="--lead">
						Насолоджуйтеся зимовими вечорами на ковзанці з нашими зручними та безпечними ковзанами для дорослих і дітей.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://images.unsplash.com/photo-1638271332547-606bf7eea7ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) bottom/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Спорядження для альпінізму
					</Text>
					<Text color="--darkL1" margin="0" font="--lead">
						Мрієте підкорити засніжені вершини? Ми пропонуємо:
					</Text>
					<Text as="p" font="--lead" color="--darkL2">
						Льодоруби, кішки, страхувальні системи.
						<br />
						Теплі куртки та штани для екстремального холоду.
						<br />
						Рюкзаки для зимових походів.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://images.unsplash.com/photo-1699516781997-21beee06a67f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) bottom/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Дитяче спорядження
					</Text>
					<Text color="--darkL1" margin="0" font="--lead">
						Маленькі спортсмени заслуговують на найкраще! У нас є спорядження для дітей будь-якого віку.
					</Text>
					<Text as="p" font="--lead" color="--darkL2">
						Гірські лижі для будь-якого рівня досвіду.
						<br />
						Сноуборди для фрістайлу, слалому та спусків.
						<br />
						Комплекти з черевиками, палицями та шоломами.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Аксесуари
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--lead">
					<Strong>
						Додайте зручності до вашої подорожі за допомогою:
						<br />
					</Strong>
					<br />
					Захисних окулярів та масок.
					<br />
					Термобілизни та рукавичок.
					<br />
					Навігаторів і ліхтариків для нічних пригод.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Гнучкі тарифи
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--lead">
					<Strong>
						Ми пропонуємо:
						<br />
					</Strong>
					<br />
					Оренду на день, вихідні або цілий сезон.
					<br />
					Знижки для сімейних груп та постійних клієнтів.
				</Text>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ми знаходимося у самому серці Карпат
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Поряд із кращими лижними курортами.{" "}
					<br />
					Обираючи Outdoor Stride Hub, ви отримуєте доступ до високоякісного спорядження і команди, яка готова допомогти у будь-який момент.
				</Text>
				<Link
					href="mailto:blank?hello@company.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@outdoorstridehub.gmail
				</Link>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0954691897
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Драгобрат, Закарпатська область, 90630
				</Text>
			</Box>
			<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
				Готові до зими? Забронюйте своє спорядження прямо{" "}
				<br />
				зараз і вирушайте у свою зимову подорож із комфортом!
			</Text>
			<Button
				max-width="300px"
				box-shadow="--m"
				border-radius="3px"
				background="linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);"
				border-color="--color-dark"
				font="--base"
				color="--light"
				type="link"
				text-decoration-line="initial"
				href="/kontakti"
				text-align="center"
				margin="0px auto 0px auto"
			>
				<Strong>
					Контакти
				</Strong>
			</Button>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});